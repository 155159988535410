<template>
  <section class="max-width-850 mx-auto">
    <h1 class="main-title text-center">Calcula tus ganancias en Spotify por tus canciones</h1>

    <span class="divider-separator"></span>

    <div class="main-container-background-spotify">
      <b-img :src="require('@/assets/images/svg/calculator-bg-spotify.svg')" class="w-100 image-background-container"></b-img>

      <div class="container-input-box">
        <label>Introduce la cantidad de reproducciones:</label>
        <component
          :is="cleave"
          v-model="data.steams_amount"
          class="form-control mb-1 text-center"
          :raw="false"
          :options="options.number"
          placeholder="10,000"
        />

        <label>Porcentaje de regalías que compartes:</label>
        <b-form-input 
          v-model="data.percentage" 
          @keypress="isNumber" 
          autocomplete="off"
          @input="validatePercentage" 
          @blur="addPercentageIcon"
          @focus="handleFocusPercentage"
          class="mb-1 text-center" 
          maxlength="3"
          placeholder="50%"
        ></b-form-input>

        <label>Ubicación de tu audiencia:</label>
        <component 
          :is="vue_select" 
          :options="$t('spotifyFreeToolSelect')" 
          label="label"
          class="mb-1 text-center"
          :clearable="false"
          v-model="data.listeners_location"
        >
          <template #option="{label}">
            <span class="avenir-medium">{{ label }}</span>
          </template>
          <template #selected-option="{label}">
            <span class="avenir-medium text-center d-block w-100">{{ label }}</span>
          </template>
        </component>

        <label>Ganancias estimadas en Dólares Americanos ($USD) :</label>
        <div class="control-form-custom">$ {{ separatebycomma(total.toFixed(2)) }}</div>

        <p class="text-muted avenir-medium m-0 mt-3">Nota: Esta estimación corresponde al monto total para el artista antes de repartir ganancias con managers, empresas discográficas y distribuidores que cobren comisión.</p>
      </div>
      <div class="container-extra-info">
        <h1 class="f-32 mr-4">Únete a BrandMe para monetizar aún más tu Talento</h1>
        <p class="avenir-medium">Encuentra oportunidades de patrocinio con Marcas líderes y explora oportunidades de Product Placement en tus videos, conciertos, eventos o colaboración en tus redes sociales.</p>
      </div>
    </div>
  </section>
</template>

<script>
import { 
  BFormInput,
  BImg,
} from 'bootstrap-vue'
import { getFormat, trimNumber, separatebycomma } from '@/libs/utils/formats';

export default {
  name: 'RoyaltiesCalculator',
  components: {
    BFormInput,
    BImg,
  },
  data() {
    return {
      getFormat,
      separatebycomma,
      cleave: null,
      vue_select: null,
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        }
      },
      data: {
        steams_amount: 0,
        percentage: '100%',
        listeners_location: this.$t('spotifyFreeToolSelect')[0],
      },
      total: 0,
    }
  },

  async mounted() {
    this.cleave = (await import('vue-cleave-component')).default;
    this.vue_select = (await import('vue-select')).default;
  },
  methods: {
    isNumber(evt) {
      evt = (evt) ? evt : window.event;
      const charCode = (evt.which) ? evt.which : evt.keyCode;
      if (((charCode > 31) && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    addPercentageIcon() {
      this.data.percentage += '%' 
    },
    handleFocusPercentage() {
      if (this.data.percentage.length > 0) this.data.percentage = this.data.percentage.split('%')[0]
    },
    validatePercentage() {
      const percetage = parseInt(this.data.percentage);
      if (percetage > 100) {
        this.data.percentage = '100';
      }
    },
  },
  watch: {
    data: {
      handler(data) {
        const listeners_data = parseFloat(data.listeners_location.value);
        const steams_amount = isNaN(data.steams_amount) ? trimNumber(data.steams_amount) : data.steams_amount

        const split_percentage = data.percentage.split('%')[0]
        const prc_royaltie = split_percentage.length === 0 ? 0 : parseInt(split_percentage)

        if (data.steams_amount.length > 0) {
          this.total = (((steams_amount) / 1000) * (listeners_data / 100)) * prc_royaltie;
        }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss" scoped>
.max-width-850 {
  max-width: 850px;
  margin-top: 3em;
  .f-32 {
    font-size: 32px;
  }

  .main-title {
    font-size: 42px;
  }
  .main-container-background-spotify {
    width: 810px;
    height: 310px;
    background-color: #06d960;
    border-radius: 0.7em;
    position: relative;
    display: flex;
    justify-content: space-around;
    padding: 2em;

    @media(max-width: 830px) {
      width: 100%;
      flex-wrap: wrap;
      margin-top: 23em;
      height: auto;
    }

    .control-form-custom {
      border: 1px solid #d8d6de;
      border-radius: 0.357rem;
      background-color: #fff;
      padding: 0.438rem 1rem;
      display: block;
      width: 100%;
      height: 2.714rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.45;
      text-align: center;
    }

    .image-background-container {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      @media(max-width: 830px) {
        width: 100%;
        height: 100%;
      }
    }

    .container-input-box {
      background-color: white;
      width: 296px;
      height: fit-content;
      flex-shrink: 0;
      border-radius: 0.7em;
      padding: 2.3em;
      box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
      z-index: 100;
      @media(max-width: 830px) {
        width: 90%;
        margin-top: -22em;
      }
      @media(max-width: 350px) {
        width: 100%;
      }
     
    }
    .container-extra-info {
      z-index: 10;
      padding: 0 3em 0 2.5em;

      @media(max-width: 830px) {
        margin-top: 2em;
      }
    }
  }
  .divider-separator {
    margin: 0 auto;
    display: block;
    background-color: #06d960;
    width: 7%;
    height: 7px;
    margin-bottom: 2.6em;
  }
}
</style>
